<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParam.topicTitle" />
        <v-select
          clearable
          :options="statusOps"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="状态"
        />
        <!-- <v-input label="社区达人姓名" v-model="searchParam.talentUserName" /> -->
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getTopicListURL, deleteTopicURL } from "./api.js";
import { setStatusOps, isTopMap, statusMap } from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      statusOps: setStatusOps(),
      searchParam: {
        communityId: null,
        topicTitle: "",
        topicType: "44",
        status: "",
      },
      tableUrl: getTopicListURL,
      headers: [
        // {
        //   prop: "talentType",
        //   label: "社区达人类型",
        //   formatter: (row, prop) => {
        //     return this.talentTypeMap[row[prop]] || "--";
        //   },
        // },
        // {
        //   prop: "topicTitle",
        //   label: "标题",
        //   formatter: (row, prop) => createImgVNode(this, row, prop),
        // },
        {
          prop: "topicTitle",
          label: "标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "isTop",
          label: "是否置顶",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closePraise",
          label: "关闭点赞",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => {
            return statusMap[row[prop]] || "--";
          },
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changeTopicType1(value) {
      console.log(value, 999);
      if (value == 11) {
        this.searchParam.topicType = value;
      }
      this.searchParam.topicType = "";
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteTopicURL}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "tennagersArticleForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "tennagersArticleForm",
        query: { id: row.id, communityId: row.communityId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
